import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"

export type SideMenuLinkProps = React.PropsWithChildren<{
  title: string
  url: string
  expandable?: boolean
  expanded?: boolean
  active?: boolean
  onToggled?: (expanded: boolean) => void
  depth?: number
  id?: string
}>

export const SideMenuLink: React.FC<SideMenuLinkProps> = ({
  title,
  url,
  expandable,
  expanded,
  active,
  onToggled,
  depth = 0,
  id,
  children,
}) => {
  const linkClasses = `group flex flex-row items-center px-6 py-2.5 cursor-pointer ${active ? `bg-[#E6EDFD] dark:bg-midnight-sky` : ``}`

  const linkContent = (
    <>
      <span className={`w-full group-hover:underline break-words`} style={{ paddingLeft: depth * 12 }}>
        {title}
      </span>

      {expandable && (
        <FontAwesomeIcon
          icon={faCaretRight}
          className={`cursor-pointer transition-transform duration-300 ease-out ${expanded ? "rotate-90" : "rotate-0"}`}
        />
      )}
    </>
  )

  const isExternalLink = url.startsWith("http")

  return (
    <li className="w-full font-rocgrotesk" id={id}>
      {expandable ? (
        <span className={linkClasses} onClick={onToggled ? () => onToggled(!expanded) : undefined}>
          {linkContent}
        </span>
      ) : isExternalLink ? (
        <a className={linkClasses} href={url}>
          {linkContent}
        </a>
      ) : (
        <Link className={linkClasses} to={url}>
          {linkContent}
        </Link>
      )}

      {expanded && <ol>{children}</ol>}
    </li>
  )
}
