import React from "react"

import { ReactChildrenProps } from "../types/ReactChildrenProps"

type SearchStateContextValue = {
  isOpen: boolean
  openSearch: () => void
  closeSearch: () => void
}

const SearchStateContext = React.createContext<SearchStateContextValue>({
  isOpen: false,
  openSearch: () => {
    // Replaced by provider
  },
  closeSearch: () => {
    // Replaced by provider
  },
})

export const SearchStateProvider: React.FC<ReactChildrenProps> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const openSearch = React.useCallback(() => {
    document.documentElement.classList.add("overflow-y-hidden")
    setIsOpen(true)
  }, [setIsOpen])

  const closeSearch = React.useCallback(() => {
    document.documentElement.classList.remove("overflow-y-hidden")
    setIsOpen(false)
  }, [setIsOpen])

  const value: SearchStateContextValue = { isOpen, openSearch, closeSearch }

  return <SearchStateContext.Provider value={value}>{children}</SearchStateContext.Provider>
}

export const useSearchState = () => {
  const context = React.useContext(SearchStateContext)

  if (context === undefined) {
    throw new Error("useSearchState must be used within a SearchStateContextProvider")
  }

  return context
}
