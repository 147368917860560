import React from "react"
import { useSearchBox } from "react-instantsearch-hooks"
import { debounce } from "lodash"

type Props = {
  inputRef: React.Ref<HTMLInputElement>
}

const Autocomplete: React.FC<Props> = ({ inputRef }) => {
  const { refine: setQuery } = useSearchBox()

  const handleOnChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }, 250)

  return (
    <input
      className={
        "border border-[#E5E5E5] dark:border-dark-navy dark:bg-midnight-sky rounded-md h-[60px] block w-full rounded-md pl-10 ring-0 focus:ring-0 font-light text-[#636363] text-lg focus:bg-white focus:text-gray-800 focus:placeholder-gray-800 dark:placeholder-white dark:placeholder-opacity-70 dark:text-white"
      }
      onChange={handleOnChange}
      placeholder="Type your search"
      ref={inputRef}
      autoFocus
      data-testid="search-box"
    />
  )
}

export default Autocomplete
