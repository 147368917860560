import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import SocialLink from "./social-link"
import { useContentSections } from "../../../hooks/content-sections"
import { useSiteMetadata } from "../../../hooks/site-metadata"
import { ContentSectionLinks } from "./content-section-links"

import FooterLogo from "../../../images/payroc_developers_footer.svg"
import FooterLogoWhite from "../../../images/payroc_developers_footer_white.svg"
import { useTheme } from "../../../context/theme"

export const PageFooter: React.FC = () => {
  const { contentSections } = useContentSections()
  const { externalLinks } = useSiteMetadata()
  const { darkMode } = useTheme()

  const contentSectionLinkElements = contentSections
    .filter((contentSection) => !contentSection.parentSection)
    .map((contentSection) => <ContentSectionLinks key={contentSection.path} contentSection={contentSection} />)

  const externalLinkElements = externalLinks.map(({ text: title, url, icon }) => (
    <a
      key={url}
      href={url}
      target="_blank"
      className="group flex flex-row items-center font-rocgrotesk font-medium text-tangerine text-[18px] leading-[27px]"
      rel="noreferrer"
    >
      {icon && <FontAwesomeIcon icon={["fal", icon]} size="1x" className="mr-3" />}
      <span className="group-hover:underline">{title}</span>
    </a>
  ))

  return (
    <footer className="mx-6 md:mx-12 xl:mx-20 text-midnight-sky dark:text-white">
      <div className="max-w-[1440px] mx-auto flex justify-between flex-col md:flex-row border-t border-t-neutral-200 pb-6">
        <div className="flex-col shrink-0 md:mr-12 mt-16">
          <div className="mb-8">
            <Link to="/" title="Visit docs.payroc.com">
              {darkMode ? <FooterLogoWhite /> : <FooterLogo />}
            </Link>
          </div>
          <div className="my-8">
            <a
              href="https://support.payroc.com/s/contactsupport"
              className="bg-tangerine hover:bg-dark-tangerine py-2 px-8 text-white rounded-md font-rocgrotesk font-medium text-[18px] leading-[24px] h-12 flex items-center justify-center"
            >
              Open a support case
            </a>
          </div>
          <div className="mt-9 flex flex-col gap-y-4">{externalLinkElements}</div>
          <div className="mt-6 mb-9 flex">
            <SocialLink url="https://www.twitter.com/@payroctech" icon="x-twitter" name="Twitter" />
            <SocialLink url="https://www.slack.com" icon="slack" name="Slack" />
            <SocialLink url="https://www.youtube.com" icon="youtube" name="YouTube" />
          </div>
          <div className="text-dark-grey dark:text-white mt-4">Copyright &copy; {new Date().getFullYear()} Payroc</div>
        </div>
        <div className="my-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 max-w-[940px]">{contentSectionLinkElements}</div>
      </div>
    </footer>
  )
}
