import React, { useState } from "react"

import { ContentSection } from "../../../../hooks/content-sections"
import { SideMenuLink } from "../side-menu-link"

type GuideCategoryMenuProps = {
  section: ContentSection
  activePath: string
  depth?: number
}

export const GuideCategoryMenu: React.FC<GuideCategoryMenuProps> = ({ section, activePath, depth = 0 }) => {
  const menuLinks: JSX.Element[] = []
  const [expanded, setExpanded] = useState(activePath.startsWith(section.path))

  for (const link of section.links) {
    menuLinks.push(<SideMenuLink key={link.url} title={link.title} url={link.url} depth={depth + 1} />)
  }

  for (const page of section.pages) {
    menuLinks.push(<SideMenuLink key={page.path} title={page.title} url={page.path} depth={depth + 1} />)
  }

  for (const article of section.articles) {
    menuLinks.push(
      <SideMenuLink key={article.path} title={article.title} url={article.path} active={article.path === activePath} depth={depth + 1} />
    )
  }

  for (const childSection of section.childSections) {
    menuLinks.push(<GuideCategoryMenu key={childSection.path} section={childSection} activePath={activePath} depth={depth + 1} />)
  }

  return (
    <SideMenuLink
      title={section.title}
      url={section.path}
      expandable={menuLinks.length > 0}
      expanded={expanded}
      onToggled={setExpanded}
      depth={depth}
    >
      {menuLinks}
    </SideMenuLink>
  )
}
