import React, { useEffect, useMemo, useRef, useState } from "react"
import { Index, useHits } from "react-instantsearch-hooks-web"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faTimes } from "@fortawesome/pro-regular-svg-icons"

import Autocomplete from "./autocomplete"
import Hit, { HitProps } from "./hit"
import { useSearchState } from "../../../context/search"
import { useContentSections } from "../../../hooks/content-sections"
import { AlgoliaIndex } from "../../../types/AlgoliaIndex"

const Results: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [activeTab, setActiveTab] = useState("")
  const [hitsContent, setHitsContent] = useState<React.ReactElement | JSX.Element[]>()
  const { hits } = useHits<HitProps["hit"]>()
  const { openSearch, closeSearch } = useSearchState()

  const { contentSections: sections } = useContentSections()
  const sectionPaths: { label: string; key: string }[] = [
    {
      label: "Operations",
      key: "operations",
    },
  ]

  for (const { title, path } of sections) {
    sectionPaths.push({ label: title, key: path })
  }

  const handleActiveTab = (key: string) => {
    if (activeTab === key) {
      setActiveTab("")
    } else {
      setActiveTab(key)
    }
  }

  useEffect(() => {
    const closeModalOnEscape = (event: KeyboardEvent) => {
      if (event.code === "Escape") closeSearch()
    }

    document.addEventListener("keydown", closeModalOnEscape)
    return () => document.removeEventListener("keydown", closeModalOnEscape)
  }, [closeSearch])

  useEffect(() => {
    openSearch()
    return () => closeSearch()
  }, [openSearch, closeSearch])

  useMemo(() => {
    if (!activeTab) setHitsContent(hits.map((props) => <Hit key={props.objectID} hit={props} />))

    if (activeTab) {
      if (hits.filter((props) => props.sectionPath === activeTab).length > 0) {
        setHitsContent(hits.filter((props) => props.sectionPath === activeTab).map((props) => <Hit key={props.objectID} hit={props} />))
      } else {
        setHitsContent(<p>No results found</p>)
      }
    }
  }, [hits, activeTab])

  return (
    <aside className="bg-[#191c2654] dark:bg-[#ffffff54] fixed h-full w-full inset-0 z-10 overflow-auto">
      <div className="bg-white dark:bg-midnight-sky h-full max-w-[1440px] min-h-[80vh] lg:h-auto lg:m-16 2xl:mx-auto z-11">
        <div className="relative">
          <div className="border-[#E5E5E5] border-b sticky pt-[60px] bg-white dark:bg-midnight-sky top-0 mb-6">
            <div className="max-w-5xl m-auto ">
              <div className="">
                <div
                  className="text-[#636363] flex flex-col text-center absolute top-4 right-4 text-2xl cursor-pointer"
                  onClick={closeSearch}
                >
                  <FontAwesomeIcon icon={faTimes} className="m-auto" />
                  <span className="text-lg leading-none">esc</span>
                </div>

                <div className="mb-8 px-2 md:px-0 relative">
                  <Autocomplete inputRef={inputRef} />
                  <FontAwesomeIcon icon={faSearch} className="absolute text-black dark:text-white top-[24px] left-4" />
                </div>

                <ul className="flex pl-0 pb-8 whitespace-nowrap overflow-auto" tabIndex={0}>
                  {sectionPaths.map(({ key, label }) => (
                    <li
                      onClick={() => handleActiveTab(key)}
                      key={key}
                      className={`font-rocgrotesk px-4 py-2 border-b-4 font-medium hover:text-[#191C26] dark:border-light-navy dark:hover:text-white hover:border-[#BBBBBB] cursor-pointer ${
                        activeTab === key
                          ? "text-[#191C26] dark:text-white dark:border-tangerine border-tangerine"
                          : "text-[#636363] border-[#E5E5E5]"
                      }`}
                    >
                      {label} {inputRef.current?.value && `(${hits.filter(({ sectionPath }) => sectionPath === key).length})`}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="max-w-5xl m-auto text-sm font-medium text-gray-500 dark:text-gray-400 dark:border-gray-700">
            <Index indexName={process.env.GATSBY_ALGOLIA_ENV === "production" ? AlgoliaIndex.PROD : AlgoliaIndex.DEV}>
              {inputRef.current?.value && hitsContent}
            </Index>
          </div>
        </div>
      </div>
    </aside>
  )
}

export default Results
