import React from "react"

import { useFocusedApiResource } from "../../../context/focused-api-resource"
import { useApiResources } from "../../../hooks/api-resources"
import { MobileResourceOperationsMenu } from "./mobile-resource-operations-menu"
import { MobileMenuLink } from "./mobile-menu-link"
import { RESOURCES_PAGE_PATH } from "../../../hooks/content-sections"
import { ApiResource } from "../../../../plugins/gatsby-source-openapi3"

type MobileResourceMenuProps = {
  onLinkClicked?: () => void
  active?: boolean
}

export const MobileResourcesMenu: React.FC<MobileResourceMenuProps> = ({ onLinkClicked, active }) => {
  const resources = useApiResources()
  const { focusedResource, focusedOperation } = useFocusedApiResource()

  React.useEffect(() => {
    if (focusedResource == null && focusedOperation == null) {
      return
    }

    const id = `nav-${focusedOperation ?? focusedResource}`
    document.getElementById(id)?.scrollIntoView()
  }, [focusedResource, focusedOperation])

  const resourceGroups = new Map<string, ApiResource[]>()

  for (const resource of resources) {
    if (!resource.group) {
      continue
    }

    if (!resourceGroups.has(resource.group)) {
      resourceGroups.set(resource.group, [])
    }

    resourceGroups.get(resource.group)?.push(resource)
  }

  const groupedResourceMenus: JSX.Element[] = []

  for (const [group, resources] of resourceGroups.entries()) {
    const resourceMenus = resources.map((resource) => (
      <MobileResourceOperationsMenu
        key={resource.slug}
        resource={resource}
        active={focusedResource === resource.slug}
        activeOperation={focusedOperation}
        onLinkClicked={onLinkClicked}
      />
    ))

    groupedResourceMenus.push(
      <div key={group} className="flex flex-col gap-y-3 mb-5">
        <p className={`font-rocgrotesk font-medium uppercase tracking-wider opacity-80`}>{group}</p>
        <div className="flex flex-col gap-y-3">{resourceMenus}</div>
      </div>
    )
  }

  const ungroupedResourceSubmenus = resources
    .filter((resource) => !resource.group)
    .map((resource) => (
      <MobileResourceOperationsMenu
        key={resource.slug}
        resource={resource}
        active={focusedResource === resource.slug}
        activeOperation={focusedOperation}
        onLinkClicked={onLinkClicked}
      />
    ))

  return (
    <div className="flex flex-col gap-y-3">
      <MobileMenuLink title="Resources" url={RESOURCES_PAGE_PATH} active={active} primary />

      {active && (
        <div className="flex flex-col gap-y-3 pl-3">
          {groupedResourceMenus}
          {ungroupedResourceSubmenus}
        </div>
      )}
    </div>
  )
}
