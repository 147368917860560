import React from "react"
import { Language } from "prism-react-renderer"
import { ReactChildrenProps } from "../types/ReactChildrenProps"

const StorageKey = "preferred_language"
const isBrowser = typeof window !== "undefined"

type PreferredLanguageContextValue = {
  preferredLanguage?: Language
  setPreferredLanguage: (language: Language) => void
}

const PreferredLanguageContext = React.createContext<PreferredLanguageContextValue>({
  setPreferredLanguage: () => {
    // Replaced by provider
  },
})

export const PreferredLanguageProvider: React.FC<ReactChildrenProps> = ({ children }) => {
  const storedPreferredLanguage = isBrowser ? (window.localStorage.getItem(StorageKey) as Language | null) : null
  const [preferredLanguage, setPreferredLanguage] = React.useState<Language | undefined>(storedPreferredLanguage ?? undefined)

  const value: PreferredLanguageContextValue = {
    preferredLanguage,
    setPreferredLanguage: (language) => {
      if (isBrowser) {
        window.localStorage.setItem(StorageKey, language)
      }

      setPreferredLanguage(language)
    },
  }

  return <PreferredLanguageContext.Provider value={value}>{children}</PreferredLanguageContext.Provider>
}

export const usePreferredLanguage = () => {
  const context = React.useContext(PreferredLanguageContext)

  if (context === undefined) {
    throw new Error("usePreferredLanguage must be used within a PreferredLanguageProvider")
  }

  return context
}
