import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"

type Props = {
  name: string
  icon: IconName
  url: string
}

const SocialLink: React.FC<Props> = ({ name, icon, url }) => {
  return (
    <div className="ring-midnight-sky dark:ring-white inline-block w-12 h-12 mr-4 hover:text-tangerine hover:ring-tangerine dark:hover:ring-tangerine items-center flex justify-center ring-2 rounded-md cursor-pointer">
      <a href={url} aria-label={name}>
        <FontAwesomeIcon icon={["fab", icon]} size="xl" />
      </a>
    </div>
  )
}

export default SocialLink
