import React from "react"

import { ContentSection } from "../../../hooks/content-sections"
import { FooterLink } from "./footer-link"

type ContentSectionLinksProps = {
  contentSection: ContentSection
}

export const ContentSectionLinks: React.FC<ContentSectionLinksProps> = ({ contentSection }) => {
  const externalLinks = contentSection.links.map(({ title, url }) => <FooterLink key={url} title={title} url={url} external />)
  const childSectionLinks = contentSection.childSections.map(({ title, path }) => <FooterLink key={path} title={title} url={path} />)
  const articleLinks = contentSection.articles.map(({ title, path }) => <FooterLink key={path} title={title} url={path} />)
  const pageLinks = contentSection.pages.map(({ title, path }) => <FooterLink key={path} title={title} url={path} />)

  return (
    <div className="flex flex-col gap-y-2">
      <FooterLink title={contentSection.title} url={contentSection.path} primary />

      {externalLinks}
      {childSectionLinks}
      {articleLinks}
      {pageLinks}
    </div>
  )
}
