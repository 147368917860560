import React from "react"
import { ApiResource } from "../../../../../plugins/gatsby-source-openapi3"
import { ResourceOperationsMenu } from "./resource-operations-menu"

export type ResourceGroupMenuProps = {
  title: string
  resources: ApiResource[]
  activeResource?: string
  activeOperation?: string
  depth?: number
}

export const ResourceGroupMenu: React.FC<ResourceGroupMenuProps> = ({ title, resources, activeResource, activeOperation, depth = 0 }) => {
  const resourceMenus = resources.map((resource) => (
    <ResourceOperationsMenu
      key={resource.slug}
      resource={resource}
      active={resource.slug === activeResource}
      activeOperation={activeOperation}
      depth={depth}
    />
  ))

  return (
    <li className="w-full font-rocgrotesk mb-5">
      <div className="px-6 py-2.5">
        <span className="font-medium uppercase tracking-wider opacity-80" style={{ paddingLeft: depth * 12 }}>
          {title}
        </span>
      </div>

      <ol>{resourceMenus}</ol>
    </li>
  )
}
