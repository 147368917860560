import React, { useState } from "react"

import { ApiResource } from "../../../../../plugins/gatsby-source-openapi3"
import { RESOURCES_PAGE_PATH } from "../../../../hooks/content-sections"
import { SideMenuLink } from "../side-menu-link"

type ResourceOperationsMenuProps = {
  resource: ApiResource
  active?: boolean
  activeOperation?: string
  depth?: number
}

export const ResourceOperationsMenu: React.FC<ResourceOperationsMenuProps> = ({ resource, active, activeOperation, depth = 0 }) => {
  const [expanded, setExpanded] = useState(active)

  const operationLinks = resource.operations.map((operation) => (
    <SideMenuLink
      key={operation.id}
      title={operation.summary}
      url={`${RESOURCES_PAGE_PATH}#${operation.id}`}
      active={activeOperation === operation.id}
      depth={depth + 1}
      id={`side-nav-${operation.id}`}
    />
  ))

  return (
    <SideMenuLink
      title={resource.name}
      url={`${RESOURCES_PAGE_PATH}#${resource.slug}`}
      expandable
      expanded={active ? true : expanded}
      onToggled={setExpanded}
      depth={depth}
    >
      {operationLinks}
    </SideMenuLink>
  )
}
