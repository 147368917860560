import React from "react"

import { ContentSectionArticle, KNOWLEDGE_SECTION_PATH, useContentSections } from "../../../../hooks/content-sections"
import { KnowledgeCategoryMenu } from "./knowledge-category-menu"
import { SideMenuLink } from "../side-menu-link"

type KnowledgeMenuProps = {
  activePath: string
}

export const KnowledgeMenu: React.FC<KnowledgeMenuProps> = ({ activePath }) => {
  const { contentSections } = useContentSections()
  const guidesSection = contentSections.find((section) => section.path === KNOWLEDGE_SECTION_PATH)

  if (!guidesSection) {
    return null
  }

  const menuLinks: JSX.Element[] = []

  for (const link of guidesSection.links) {
    menuLinks.push(<SideMenuLink key={link.url} title={link.title} url={link.url} />)
  }

  for (const page of guidesSection.pages) {
    menuLinks.push(<SideMenuLink key={page.path} title={page.title} url={page.path} />)
  }

  const introArticle = guidesSection.articles.find(isIntroArticle)

  if (!introArticle) {
    throw new Error("Intro article not found")
  }

  const articles = guidesSection.articles.filter((article) => !isIntroArticle(article))

  articles.sort((a, b) => {
    if (a.title < b.title) return -1
    if (a.title > b.title) return 1
    return 0
  })

  articles.unshift(introArticle)
  guidesSection.articles = articles

  for (const article of guidesSection.articles) {
    menuLinks.push(<SideMenuLink key={article.path} title={article.title} url={article.path} active={article.path === activePath} />)
  }

  for (const childSection of guidesSection.childSections) {
    menuLinks.push(<KnowledgeCategoryMenu key={childSection.path} section={childSection} activePath={activePath} />)
  }

  return <>{menuLinks}</>
}

export function isIntroArticle(article: ContentSectionArticle): boolean {
  return article.title.toLowerCase().startsWith("intro")
}
