import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import algoliasearch from "algoliasearch/lite"

import Results from "./results"
import { HitsPerPage, InstantSearch } from "react-instantsearch-hooks-web"
import { useSearchState } from "../../../context/search"
import { AlgoliaIndex } from "../../../types/AlgoliaIndex"

type Props = {
  onInput?: React.FormEventHandler<HTMLInputElement>
}

const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID as string, process.env.GATSBY_ALGOLIA_SEARCH_KEY as string)

const SearchBar: React.FC<Props> = ({ onInput }): React.ReactElement => {
  const { openSearch, isOpen } = useSearchState()

  return (
    <>
      <div className="w-full h-auto">
        <div className="inset-y-0 right-0 pr-3 flex items-center text-white">
          <FontAwesomeIcon icon={faSearch} onClick={openSearch} cursor="pointer" data-testid="search-button" />
        </div>
      </div>

      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.GATSBY_ALGOLIA_ENV === "production" ? AlgoliaIndex.PROD : AlgoliaIndex.DEV}
      >
        <HitsPerPage
          className="hidden"
          defaultValue={100}
          items={[
            {
              value: 100,
              default: true,
              label: "",
            },
          ]}
        />

        {isOpen && <Results />}
      </InstantSearch>
    </>
  )
}

export default SearchBar
