import { graphql, useStaticQuery } from "gatsby"
import { ApiResource } from "../../plugins/gatsby-source-openapi3"

const apiResourcesQuery = graphql`
  query ApiResourcesQuery {
    allApiResource(sort: { fields: group }) {
      resources: nodes {
        name
        description
        slug
        group
        operations {
          id
          method
          path
          summary
          description
          parameters
          requestBody
          responses {
            statusCode
            description
            content
            headers
          }
          codeSamples {
            name
            language
            source
          }
          events
        }
      }
    }
  }
`

type ApiResourcesQueryResult = {
  allApiResource: {
    resources: ApiResource[]
  }
}

export function useApiResources(): ApiResource[] {
  const {
    allApiResource: { resources },
  } = useStaticQuery<ApiResourcesQueryResult>(apiResourcesQuery)

  return resources
}
