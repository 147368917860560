import React from "react"

import { MobileMenuLink } from "./mobile-menu-link"
import { useApiEvents } from "../../../hooks/api-events"
import { useFocusedEvent } from "../../../hooks/focused-event"
import { EVENTS_PAGE_PATH } from "../../../hooks/content-sections"

type MobileEventsMenuProps = {
  onLinkClicked?: () => void
  active?: boolean
}

export const MobileEventsMenu: React.FC<MobileEventsMenuProps> = ({ onLinkClicked, active }) => {
  const events = useApiEvents()
  const { focusedEvent } = useFocusedEvent()

  React.useEffect(() => {
    if (!focusedEvent) {
      return
    }

    const id = `nav-${focusedEvent}`
    document.getElementById(id)?.scrollIntoView()
  }, [focusedEvent])

  if (events.length === 0) {
    return null
  }

  const eventLinks = events.map((event) => (
    <MobileMenuLink
      key={event.name}
      title={event.name}
      url={`${EVENTS_PAGE_PATH}#${event.name}`}
      active={focusedEvent === event.name}
      anchor={event.name}
      onClick={onLinkClicked}
    />
  ))

  return (
    <div className="flex flex-col gap-y-3">
      <MobileMenuLink title="Events" url={EVENTS_PAGE_PATH} active={active} primary />

      {active && <div className="flex flex-col gap-y-3 pl-3">{eventLinks}</div>}
    </div>
  )
}
