import { IconName } from "@fortawesome/fontawesome-svg-core"
import { graphql, useStaticQuery } from "gatsby"

export type NavigationLink = {
  title: string
  url: string
}

export type ExternalLink = {
  text: string
  url: string
  isButton?: boolean
  icon?: IconName
  excludeFromHeader?: boolean
}

export type LandingPageCard = {
  title: string
  excerpt: string
  icon: IconName
  url: string
}

export type SiteMetadata = {
  title: string
  description: string
  externalLinks: ExternalLink[]
  landingPageCards: LandingPageCard[]
  navigationLinks: NavigationLink[]
}

export function useSiteMetadata(): SiteMetadata {
  const {
    site: { siteMetadata },
  } = useStaticQuery<SiteMetadataQueryResult>(siteMetadataQuery)

  return siteMetadata
}

type SiteMetadataQueryResult = {
  site: {
    siteMetadata: {
      description: string
      title: string
      externalLinks: ExternalLink[]
      landingPageCards: LandingPageCard[]
      navigationLinks: NavigationLink[]
    }
  }
}

const siteMetadataQuery = graphql`
  query SiteMetadataQuery {
    site {
      siteMetadata {
        title
        description
        navigationLinks {
          title
          url
        }
        externalLinks {
          text
          url
          isButton
          icon
          excludeFromHeader
        }
        landingPageCards {
          title
          excerpt
          icon
          url
        }
      }
    }
  }
`
