import { IconName } from "@fortawesome/fontawesome-svg-core"
import { graphql, useStaticQuery } from "gatsby"
import { useMemo } from "react"

import { ContentSectionLink, ContentSectionPage } from "../../plugins/gatsby-transformer-content/gatsby-node"
import { isIntroArticle } from "../components/page/menu/knowledge/knowledge-menu"

export const API_SECTION_PATH = "/api"
export const RESOURCES_PAGE_PATH = "/api/resources"
export const EVENTS_PAGE_PATH = "/api/events"
export const GUIDES_SECTION_PATH = "/guides"

export const KNOWLEDGE_SECTION_PATH = "/knowledge"

export type ContentSectionArticle = {
  title: string
  path: string
  icon: IconName
  featured: boolean
  excerpt: string
  metaTitle?: string
  metaDescription?: string
}

export type ContentSection = {
  title: string
  path: string
  articles: ContentSectionArticle[]
  synopsis: string
  icon: IconName
  order?: number
  links: ContentSectionLink[]
  pages: ContentSectionPage[]
  parentSection?: ContentSection
  childSections: ContentSection[]
}

export type PathComponents = {
  sectionPath: string
  pageSlug?: string
  fullPath: string
}

export type PathContent = {
  sections: ContentSection[]
  article?: ContentSectionArticle
  page?: ContentSectionPage
}

type ContentSectionsHook = {
  contentSections: ContentSection[]
  getContentForPath: (path: string) => PathContent | undefined
}

export function useContentSections(): ContentSectionsHook {
  const {
    allContentSection: { sectionNodes },
    allFile: { sectionIndexFileNodes },
    allContentSectionArticle: { articleNodes },
  } = useStaticQuery<ContentSectionsQueryResult>(contentSectionsQuery)

  return useMemo<ContentSectionsHook>(() => {
    const contentSectionsByPath = new Map<string, ContentSection>()

    for (const sectionNode of sectionNodes) {
      const section = parseContentSection(sectionNode, articleNodes, sectionIndexFileNodes)
      contentSectionsByPath.set(section.path, section)
    }

    const contentSections = Array.from(contentSectionsByPath.values())

    for (const contentSection of contentSections) {
      const lastSeparatorIndex = contentSection.path.lastIndexOf("/")

      if (lastSeparatorIndex <= 0) {
        continue
      }

      const parentPath = contentSection.path.substring(0, lastSeparatorIndex)
      const parentContentSection = contentSectionsByPath.get(parentPath)

      if (!parentContentSection) {
        continue
      }

      parentContentSection.childSections.push(contentSection)
      contentSection.parentSection = parentContentSection
    }

    contentSections.sort(compareContentSectionsByOrder)

    return {
      contentSections,
      getContentForPath: (path) => getContentForPath(path.replace(/\/$/, ""), contentSectionsByPath),
    }
  }, [sectionNodes, sectionIndexFileNodes, articleNodes])
}

function parseContentSection(
  sectionNode: ContentSectionNode,
  articleNodes: ContentSectionArticleNode[],
  indexFileNodes: ContentSectionIndexFileNode[]
): ContentSection {
  const articles: ContentSectionArticle[] = []

  for (const articleNode of articleNodes) {
    if (articleNode.sectionPath !== sectionNode.path) {
      continue
    }

    articles.push({
      title: articleNode.title,
      path: articleNode.path,
      icon: articleNode.icon,
      featured: articleNode.featured,
      excerpt: articleNode.intro ?? articleNode.parent.excerpt,
    })
  }

  const indexFileNode = indexFileNodes.find((node) => node.relativeDirectory === sectionNode.path)

  const section: ContentSection = {
    title: sectionNode.title,
    path: sectionNode.path,
    articles,
    synopsis: sectionNode.synopsis ?? indexFileNode?.childMdx?.excerpt ?? "",
    icon: sectionNode.icon,
    order: sectionNode.order,
    links: sectionNode.links ?? [],
    pages: sectionNode.pages ?? [],
    childSections: [],
  }

  if (section.path === API_SECTION_PATH) {
    section.articles.sort((a, b) => (a.path === section.path ? -1 : b.path === section.path ? 1 : a.title.localeCompare(b.title)))
  }

  if (section.path === KNOWLEDGE_SECTION_PATH) {
    const introArticle = section.articles.find(isIntroArticle)

    if (!introArticle) {
      throw new Error("Intro article not found")
    }

    const articles = section.articles.filter((article) => !isIntroArticle(article))

    articles.sort((a, b) => {
      if (a.title < b.title) return -1
      if (a.title > b.title) return 1
      return 0
    })

    articles.unshift(introArticle)
    section.articles = articles
  }

  return section
}

function compareContentSectionsByOrder(a: ContentSection, b: ContentSection): number {
  if (a.order != null && b.order != null) {
    return a.order - b.order
  }

  if (a.order != null && b.order == null) {
    return -1
  }

  if (a.order == null && b.order != null) {
    return 1
  }

  return 0
}

function getContentForPath(path: string, contentSectionsByPath: Map<string, ContentSection>): PathContent | undefined {
  let sectionPath = path

  if (!contentSectionsByPath.has(sectionPath)) {
    const lastSeparatorIndex = sectionPath.lastIndexOf("/")

    if (lastSeparatorIndex <= 0) {
      return
    }

    sectionPath = sectionPath.substring(0, lastSeparatorIndex)
  }

  const section = contentSectionsByPath.get(sectionPath)

  if (!section) {
    return
  }

  const sections: ContentSection[] = [section]
  let parentSection = section.parentSection

  while (parentSection != null) {
    sections.unshift(parentSection)
    parentSection = parentSection.parentSection
  }

  const article = sections[sections.length - 1].articles.find((article) => article.path === path)

  let page: ContentSectionPage | undefined

  if (!article) {
    page = sections[sections.length - 1].pages.find((page) => page.path === path)
  }

  return { sections, article, page }
}

type ContentSectionNode = {
  title: string
  path: string
  icon: IconName
  synopsis?: string
  order?: number
  links?: ContentSectionLink[]
  pages?: ContentSectionPage[]
}

type ContentSectionIndexFileNode = {
  relativeDirectory: string
  childMdx: {
    excerpt: string
  }
}

export type ContentSectionArticleNode = {
  id?: string
  title: string
  path: string
  sectionPath: string
  icon: IconName
  featured: boolean
  parent: {
    excerpt: string
  }
  intro?: string
}

type ContentSectionsQueryResult = {
  allContentSection: {
    sectionNodes: ContentSectionNode[]
  }
  allFile: {
    sectionIndexFileNodes: ContentSectionIndexFileNode[]
  }
  allContentSectionArticle: {
    articleNodes: ContentSectionArticleNode[]
  }
}

const contentSectionsQuery = graphql`
  query ContentSectionsQuery {
    allContentSection {
      sectionNodes: nodes {
        title
        path
        order
        synopsis
        icon
        links {
          title
          icon
          excerpt
          url
          featured
        }
        pages {
          title
          icon
          excerpt
          path
          featured
          hasCustomMenu
        }
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "content" }, name: { eq: "index" } }) {
      sectionIndexFileNodes: nodes {
        relativeDirectory
        childMdx {
          excerpt(pruneLength: 140)
        }
      }
    }
    allContentSectionArticle(sort: { fields: publishedDate, order: ASC }) {
      articleNodes: nodes {
        title
        path
        sectionPath
        icon
        featured
        parent {
          ... on Mdx {
            excerpt(pruneLength: 140)
          }
        }
        intro
      }
    }
  }
`
