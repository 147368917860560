import { create } from "zustand"
import { devtools } from "zustand/middleware"

type FocusedEventState = {
  focusedEvent?: string
  setFocusedEvent: (event?: string) => void
}

export const useFocusedEvent = create<FocusedEventState>()(
  devtools((set) => ({
    focusedEvent: undefined,
    setFocusedEvent: (event?: string) => set({ focusedEvent: event }),
  }))
)
