import React from "react"
import { ReactChildrenProps } from "../types/ReactChildrenProps"

const storageKey = "theme"

type ThemeContextValue = {
  darkMode?: boolean
  enableLightMode: () => void
  enableDarkMode: () => void
}

const ThemeContext = React.createContext<ThemeContextValue>({
  darkMode: false,
  enableLightMode: () => {
    // Replaced by provider
  },
  enableDarkMode: () => {
    // Replaced by provider
  },
})

export const ThemeProvider: React.FC<ReactChildrenProps> = ({ children }) => {
  const [darkMode, setDarkMode] = React.useState<boolean>()

  React.useEffect(() => {
    setDarkMode(document.documentElement.classList.contains("dark"))
  }, [setDarkMode])

  const enableLightMode = React.useCallback(() => {
    document.documentElement.classList.remove("dark")
    localStorage.setItem(storageKey, "light")

    setDarkMode(false)
  }, [setDarkMode])

  const enableDarkMode = React.useCallback(() => {
    document.documentElement.classList.add("dark")
    localStorage.setItem(storageKey, "dark")

    setDarkMode(true)
  }, [setDarkMode])

  const value: ThemeContextValue = { darkMode, enableLightMode, enableDarkMode }

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

export const useTheme = () => {
  const context = React.useContext(ThemeContext)

  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider")
  }

  return context
}
