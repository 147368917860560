import React from "react"
import { Link } from "gatsby"
import { ReactChildrenProps } from "../../../types/ReactChildrenProps"

interface Props extends ReactChildrenProps {
  url: string
  external?: boolean
  isButton?: boolean
}

export const NavBarLink: React.FC<Props> = ({ children, external, isButton, url }) => {
  const classNames = isButton ? "bg-[#7b61ff66] py-1 px-3 rounded-full border border-[#7B61FF] leading-[24px]" : "hover:text-tangerine"

  if (external) {
    return (
      <a href={url} className={classNames}>
        {children}
      </a>
    )
  }

  return (
    <Link to={url} className={classNames}>
      {children}
    </Link>
  )
}
