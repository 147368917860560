import React from "react"

import { GUIDES_SECTION_PATH, useContentSections } from "../../../../hooks/content-sections"
import { GuideCategoryMenu } from "./guide-category-menu"
import { SideMenuLink } from "../side-menu-link"

type GuidesMenuProps = {
  activePath: string
}

export const GuidesMenu: React.FC<GuidesMenuProps> = ({ activePath }) => {
  const { contentSections } = useContentSections()
  const guidesSection = contentSections.find((section) => section.path === GUIDES_SECTION_PATH)

  if (!guidesSection) {
    return null
  }

  const menuLinks: JSX.Element[] = []

  for (const link of guidesSection.links) {
    menuLinks.push(<SideMenuLink key={link.url} title={link.title} url={link.url} />)
  }

  for (const page of guidesSection.pages) {
    menuLinks.push(<SideMenuLink key={page.path} title={page.title} url={page.path} />)
  }

  for (const article of guidesSection.articles) {
    menuLinks.push(<SideMenuLink key={article.path} title={article.title} url={article.path} active={article.path === activePath} />)
  }

  for (const childSection of guidesSection.childSections) {
    menuLinks.push(<GuideCategoryMenu key={childSection.path} section={childSection} activePath={activePath} />)
  }

  return <>{menuLinks}</>
}
