import React from "react"
import { Switch } from "@headlessui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMoon, faSun } from "@fortawesome/pro-solid-svg-icons"

import { useTheme } from "../../../context/theme"
import { classNames } from "../../../utils"

const DarkModeToggle = () => {
  const { darkMode, enableDarkMode, enableLightMode } = useTheme()

  const toggleTheme = React.useCallback(
    (checked: boolean) => (checked ? enableDarkMode() : enableLightMode()),
    [enableDarkMode, enableLightMode]
  )

  return (
    <Switch
      checked={darkMode ?? false}
      onChange={toggleTheme}
      className={classNames(
        typeof darkMode !== "boolean" ? "invisible" : "relative",
        "bg-light-navy inline-flex flex-shrink-0 h-6 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200"
      )}
      data-testid="dark-mode-toggle"
    >
      <span className="sr-only">Use setting</span>
      <span
        className={classNames(
          darkMode ? "translate-x-6" : "translate-x-0",
          "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white transition ease-in-out duration-200"
        )}
      >
        <span
          className={classNames(
            darkMode ? "opacity-0 ease-out duration-100" : "opacity-100 ease-in duration-200",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <FontAwesomeIcon icon={faSun} className="h-4 w-4 text-tangerine" />
        </span>
        <span
          className={classNames(
            darkMode ? "opacity-100 ease-in duration-200" : "opacity-0 ease-out duration-100",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <FontAwesomeIcon icon={faMoon} className="h-3 w-3 text-gray-400" fill="currentColor" />
        </span>
      </span>
    </Switch>
  )
}

export default DarkModeToggle
