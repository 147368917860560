import React from "react"

import { API_SECTION_PATH, GUIDES_SECTION_PATH, KNOWLEDGE_SECTION_PATH, useContentSections } from "../../../hooks/content-sections"
import { EventsMenu } from "./events/events-menu"
import { GuidesMenu } from "./guides/guides-menu"
import { KnowledgeMenu } from "./knowledge/knowledge-menu"
import { ResourcesMenu } from "./resources/resources-menu"
import { SideMenuLink } from "./side-menu-link"

type Props = {
  path: string
}

export const PageMenu = React.memo<Props>(({ path }) => {
  const { getContentForPath } = useContentSections()
  const content = getContentForPath(path)
  if (!content || (!content.article && !content.page)) {
    return null
  }

  const section = content.sections[content.sections.length - 1]

  const externalLinks = section.links.map(({ title, url }) => <SideMenuLink key={url} title={title} url={url} />)
  const pageLinks = section.pages
    .filter((page) => !page.hasCustomMenu)
    .map(({ title, path: pagePath }) => <SideMenuLink key={pagePath} title={title} url={pagePath} active={pagePath === path} />)

  let articleLinks: JSX.Element[] | undefined

  if (section.path !== GUIDES_SECTION_PATH) {
    articleLinks = section.articles.map(({ title, path: articlePath }) => (
      <SideMenuLink key={articlePath} title={title} url={articlePath} active={articlePath === path} />
    ))
  }

  return (
    <div className="hidden xl:block fixed z-50 top-[length:var(--navbar-height)] w-[length:var(--sidebar-width)] h-[calc(100vh-var(--navbar-height))] bg-sky-blue dark:bg-light-navy py-8 overflow-y-auto">
      <ol>
        {section.path.startsWith(GUIDES_SECTION_PATH) ? (
          <GuidesMenu activePath={path} />
        ) : section.path.startsWith(KNOWLEDGE_SECTION_PATH) ? (
          <KnowledgeMenu activePath={path} />
        ) : (
          <>
            {externalLinks}
            {articleLinks}
            {pageLinks}

            {section.path === API_SECTION_PATH && (
              <li className="mt-4">
                <ResourcesMenu />

                <EventsMenu />
              </li>
            )}
          </>
        )}
      </ol>
    </div>
  )
})
