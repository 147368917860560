import "@fortawesome/fontawesome-svg-core/styles.css"
import "react-toastify/dist/ReactToastify.css"

import React from "react"
import { config, library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fal } from "@fortawesome/pro-light-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/pro-regular-svg-icons"
import { Prism } from "prism-react-renderer"
import { ToastContainer } from "react-toastify"

import { PageFooter } from "./footer"
import { PageHeader } from "./header"
import { PageMenu } from "./menu"
import { classNames } from "../../utils"
import { useContentSections } from "../../hooks/content-sections"
import { ReactChildrenProps } from "../../types/ReactChildrenProps"

// Import FontAwesome icon packs for global use
config.autoAddCss = false
library.add(fab, fal, fas, far)

// Load additional languages for Prism syntax highlighting
let globalObject: Record<string, unknown>

if (global != null) {
  globalObject = global as Record<string, unknown>
} else {
  globalObject = window as unknown as Record<string, unknown>
}

globalObject.Prism = Prism

require("prismjs/components/prism-csharp")

interface Props extends ReactChildrenProps {
  path: string
  params: Record<string, string>
}

const PageLayout: React.FC<Props> = ({ path, children }) => {
  const { getContentForPath } = useContentSections()
  const content = getContentForPath(path)
  const hasMenu = content != null && (content.article != null || content.page != null)

  return (
    <div className="bg-white dark:bg-dark-navy text-dark-grey dark:text-sky-blue font-sans min-h-screen">
      <PageHeader path={path} />
      <PageMenu path={path} />

      <div
        className={classNames(
          hasMenu ? "xl:left-[length:var(--sidebar-width)] xl:w-[calc(100%-var(--sidebar-width))]" : "",
          "relative mt-[length:var(--navbar-height)]"
        )}
      >
        {children}
        <PageFooter />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.embeddedChatbotConfig = {
              "chatbotId" : "rhZn968OvxbvorTJvUdat",
              "domain": "www.chatbase.co"
            }
          `,
          }}
        ></script>
        {/* @ts-ignore */}
        <script src="https://www.chatbase.co/embed.min.js" chatbotId="rhZn968OvxbvorTJvUdat" domain="www.chatbase.co" defer></script>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  )
}

export default PageLayout
