import React from "react"
import { ReactChildrenProps } from "../types/ReactChildrenProps"

type FocusedApiResourceContextValue = {
  focusedResource?: string
  focusedOperation?: string
  setFocus: (resource: string, operation?: string) => void
  clearFocus: () => void
}

const FocusedApiResourceContext = React.createContext<FocusedApiResourceContextValue>({
  setFocus: () => {
    // Replaced by provider
  },
  clearFocus: () => {
    // Replaced by provider
  },
})

export const FocusedApiResourceProvider: React.FC<ReactChildrenProps> = ({ children }) => {
  const [focusedResource, setFocusedResource] = React.useState<string>()
  const [focusedOperation, setFocusedOperation] = React.useState<string>()
  const setFocus = React.useCallback(
    (resource: string, operation?: string) => {
      setFocusedResource(resource)
      setFocusedOperation(operation)
    },
    [setFocusedResource, setFocusedOperation]
  )

  const clearFocus = React.useCallback(() => {
    setFocusedResource(undefined)
    setFocusedOperation(undefined)
  }, [setFocusedResource, setFocusedOperation])

  const value: FocusedApiResourceContextValue = {
    focusedResource,
    focusedOperation,
    setFocus,
    clearFocus,
  }

  return <FocusedApiResourceContext.Provider value={value}>{children}</FocusedApiResourceContext.Provider>
}

export const useFocusedApiResource = () => {
  const context = React.useContext(FocusedApiResourceContext)

  if (context === undefined) {
    throw new Error("useFocusedApiResource must be used within a FocusedApiResourceProvider")
  }

  return context
}
