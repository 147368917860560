import { graphql, useStaticQuery } from "gatsby"
import { ApiEvent } from "../../plugins/gatsby-source-openapi3/api-parser"

const apiEventsQuery = graphql`
  query ApiEventsQuery {
    allApiEvent {
      events: nodes {
        name
        description
        schemaData
      }
    }
  }
`

type ApiEventsQueryResult = {
  allApiEvent: {
    events: ApiEvent[]
  }
}

export function useApiEvents(): ApiEvent[] {
  const {
    allApiEvent: { events },
  } = useStaticQuery<ApiEventsQueryResult>(apiEventsQuery)

  return events
}
