import React from "react"

import { ApiResource } from "../../../../plugins/gatsby-source-openapi3"
import { RESOURCES_PAGE_PATH } from "../../../hooks/content-sections"
import { MobileMenuLink } from "./mobile-menu-link"

type MobileResourceOperationsMenuProps = {
  resource: ApiResource
  active?: boolean
  activeOperation?: string
  onLinkClicked?: () => void
}

export const MobileResourceOperationsMenu: React.FC<MobileResourceOperationsMenuProps> = ({
  resource,
  active,
  activeOperation,
  onLinkClicked,
}) => {
  const operationLinks = resource.operations.map((operation) => (
    <MobileMenuLink
      key={operation.id}
      title={operation.summary}
      url={`${RESOURCES_PAGE_PATH}#${operation.id}`}
      active={activeOperation === operation.id}
      anchor={operation.id}
      onClick={onLinkClicked}
    />
  ))

  return (
    <div className="flex flex-col gap-y-3">
      <MobileMenuLink
        title={resource.name}
        url={`${RESOURCES_PAGE_PATH}#${resource.slug}`}
        active={active}
        anchor={resource.slug}
        primary
      />

      {active && <div className="flex flex-col gap-y-3 pl-3">{operationLinks}</div>}
    </div>
  )
}
