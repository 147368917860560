import React from "react"
import { ReactChildrenProps } from "../types/ReactChildrenProps"

type MobileNavMenuStateContextValue = {
  isOpen: boolean
  openMenu: () => void
  closeMenu: () => void
}

const MobileNavMenuStateContext = React.createContext<MobileNavMenuStateContextValue>({
  isOpen: false,
  openMenu: () => {
    // Replaced by provider
  },
  closeMenu: () => {
    // Replaced by provider
  },
})

export const MobileNavMenuStateProvider: React.FC<ReactChildrenProps> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const openMenu = React.useCallback(() => {
    document.documentElement.classList.add("overflow-y-hidden")
    setIsOpen(true)
  }, [setIsOpen])

  const closeMenu = React.useCallback(() => {
    document.documentElement.classList.remove("overflow-y-hidden")
    setIsOpen(false)
  }, [setIsOpen])

  const value: MobileNavMenuStateContextValue = { isOpen, openMenu, closeMenu }

  return <MobileNavMenuStateContext.Provider value={value}>{children}</MobileNavMenuStateContext.Provider>
}

export const useMobileNavMenuState = () => {
  const context = React.useContext(MobileNavMenuStateContext)

  if (context === undefined) {
    throw new Error("useMobileNavMenuState must be used within a MobileNavMenuStateProvider")
  }

  return context
}
