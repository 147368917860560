import React, { useEffect } from "react"

import { useMobileNavMenuState } from "../../../context/mobile-nav-menu"
import { useContentSections } from "../../../hooks/content-sections"
import { useSiteMetadata } from "../../../hooks/site-metadata"
import { MobileContentSectionLinks } from "./mobile-content-section-links"
import { MobileMenuLink } from "./mobile-menu-link"

type Props = {
  path: string
  onLinkClicked: () => void
}

export const MobileNavigationMenu = React.memo<Props>(({ path, onLinkClicked }) => {
  const { openMenu, closeMenu } = useMobileNavMenuState()

  React.useEffect(() => {
    openMenu()

    return () => {
      closeMenu()
    }
  }, [openMenu, closeMenu])

  const { contentSections, getContentForPath } = useContentSections()

  useEffect(() => {
    const content = getContentForPath(path)

    if (!content || content.page?.hasCustomMenu) {
      return
    }

    const { sections, article, page } = content
    const section = sections[sections.length - 1]

    const id = `nav-${page?.path ?? article?.path ?? section.path}`
    document.getElementById(id)?.scrollIntoView()
  }, [path, getContentForPath])

  const { externalLinks } = useSiteMetadata()

  const contentSectionLinkElements = contentSections
    .filter((contentSection) => !contentSection.parentSection)
    .map((contentSection) => (
      <MobileContentSectionLinks
        key={contentSection.path}
        contentSection={contentSection}
        activePath={path}
        onLinkClicked={onLinkClicked}
      />
    ))

  const externalLinkElements = externalLinks.map(({ text: title, url }) => (
    <MobileMenuLink key={url} title={title} url={url} external primary />
  ))

  return (
    <div className="flex flex-col gap-y-3 items-start p-6" data-testid="mobile-nav-menu">
      {contentSectionLinkElements}

      {externalLinkElements}
    </div>
  )
})
