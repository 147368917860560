import React from "react"
import { Hit as AlgoliaHit } from "instantsearch.js/es/types"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { Link } from "gatsby"
import { Highlight } from "react-instantsearch-hooks-web"

import { useSearchState } from "../../../context/search"
import { RESOURCES_PAGE_PATH } from "../../../hooks/content-sections"

export type HitProps = {
  hit: AlgoliaHit<{
    icon: IconName | null
    path: string
    sectionPath: string
    title: string
    excerpt: string
    isApiResource: boolean
    operations?: {
      id: string
    }[]
  }>
}

const Hit: React.FC<HitProps> = ({ hit }) => {
  const { closeSearch } = useSearchState()
  const toPath = hit.isApiResource ? `${RESOURCES_PAGE_PATH}#${hit.path}` : hit.path

  return (
    <Link
      onClick={closeSearch}
      to={toPath}
      className="px-3 py-2 block text-black mb-8 text-lg hover:bg-gray-100 dark:hover:bg-dark-navy rounded-lg"
      tabIndex={0}
    >
      <Highlight
        hit={hit}
        attribute={"title"}
        className="text-[#191C26] dark:text-white mb-2 text-2xl font-medium font-rocgrotesk block"
        classNames={{ highlighted: "bg-[#F65F1833] dark:bg[#fff] py-2" }}
      />
      <p className="text-tangerine mb-2">{toPath}</p>

      <Highlight hit={hit} attribute={"excerpt"} className="text-[#636363] dark:text-white" classNames={{ highlighted: "bg-[#F65F1833]" }}>
        {hit.excerpt}
      </Highlight>
    </Link>
  )
}

export default Hit
