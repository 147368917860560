import React from "react"
import { Link } from "gatsby"

export type FooterLinkProps = {
  title: string
  url: string
  external?: boolean
  primary?: boolean
}

export const FooterLink: React.FC<FooterLinkProps> = ({ title, url, external, primary }) => {
  const classes = `font-rocgrotesk hover:text-tangerine ${
    primary ? `text-[18px] leading-[27px] font-medium hover:underline` : `font-normal underline`
  }`

  return external ? (
    <a href={url} className={classes}>
      {title}
    </a>
  ) : (
    <Link to={url} className={classes}>
      {title}
    </Link>
  )
}
