import React, { useEffect } from "react"
import { ApiResource } from "../../../../../plugins/gatsby-source-openapi3"

import { useFocusedApiResource } from "../../../../context/focused-api-resource"
import { useApiResources } from "../../../../hooks/api-resources"
import { RESOURCES_PAGE_PATH } from "../../../../hooks/content-sections"
import { ResourceGroupMenu } from "./resource-group-menu"
import { ResourceOperationsMenu } from "./resource-operations-menu"
import { SideMenuLink } from "../side-menu-link"

export const ResourcesMenu: React.FC = () => {
  const resources = useApiResources()
  const { focusedResource, focusedOperation } = useFocusedApiResource()

  useEffect(() => {
    if (!focusedOperation) {
      return
    }

    document.getElementById(`side-nav-${focusedOperation}`)?.scrollIntoView()
  }, [focusedOperation])

  const resourceGroups = new Map<string, ApiResource[]>()

  for (const resource of resources) {
    if (!resource.group) {
      continue
    }

    if (!resourceGroups.has(resource.group)) {
      resourceGroups.set(resource.group, [])
    }

    resourceGroups.get(resource.group)?.push(resource)
  }

  const groupedResourceMenus: JSX.Element[] = []

  for (const [group, resources] of resourceGroups.entries()) {
    groupedResourceMenus.push(
      <ResourceGroupMenu
        key={group}
        title={group}
        resources={resources}
        activeResource={focusedResource}
        activeOperation={focusedOperation}
        depth={1}
      />
    )
  }

  const ungroupedResourceMenus = resources
    .filter((resource) => !resource.group)
    .map((resource) => (
      <ResourceOperationsMenu
        key={resource.slug}
        resource={resource}
        active={focusedResource === resource.slug}
        activeOperation={focusedOperation}
        depth={1}
      />
    ))

  return (
    <SideMenuLink title="Resources" url={RESOURCES_PAGE_PATH} expanded>
      {groupedResourceMenus}
      {ungroupedResourceMenus}
    </SideMenuLink>
  )
}
