import React from "react"
import { Link } from "gatsby"
import { Disclosure } from "@headlessui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/pro-light-svg-icons"

import DarkModeToggle from "./dark-mode-toggle"
import SearchIcon from "../../common/search/icon"
import { MobileNavigationMenu } from "./mobile-navigation-menu"
import { NavBarLink } from "./nav-bar-link"
import { useSiteMetadata } from "../../../hooks/site-metadata"

import HeaderLogo from "../../../images/payroc_developers_header.svg"

type Props = {
  path: string
}

export const PageHeader: React.FC<Props> = ({ path }) => {
  const { externalLinks, navigationLinks } = useSiteMetadata()

  const links: JSX.Element[] = [
    ...navigationLinks.map(({ title, url }) => (
      <NavBarLink key={title} url={url}>
        {title}
      </NavBarLink>
    )),
    ...externalLinks
      .filter((link) => !link.excludeFromHeader)
      .map((link) => (
        <NavBarLink key={link.text} url={link.url} isButton={link.isButton} external>
          {link.text}
        </NavBarLink>
      )),
  ]

  return (
    <Disclosure as="header" className="bg-midnight-sky w-full fixed top-0 z-[60] flex flex-col" data-testid="header">
      {({ open, close }) => (
        <>
          <div className="px-6 md:px-12 xl:px-20">
            <div className="w-full max-w-[1440px] h-[length:var(--navbar-height)] mx-auto flex flex-row items-center justify-between gap-x-4 shrink-0">
              <div className="flex flex-row items-center gap-x-6">
                <Link to="/" title="Visit docs.payroc.com">
                  <HeaderLogo className="w-[70px] md:w-full" />
                </Link>

                <DarkModeToggle />
              </div>

              <div className="hidden xl:flex flex-row items-center justify-center gap-x-10 grow shrink-0 text-white font-rocgrotesk font-medium text-[18px] leading-[27px] text-center">
                {links}
              </div>

              <div className="flex flex-row items-center gap-x-6">
                <div className="md:flex">
                  <SearchIcon />
                </div>

                <Disclosure.Button
                  className="flex xl:hidden w-8 h-8 items-center justify-center rounded-sm text-white hover:bg-light-navy focus:outline-none focus:ring-2 focus:ring-inset focus:ring-disabled-navy"
                  data-testid="nav-menu-toggle"
                  aria-label="Menu"
                >
                  <FontAwesomeIcon icon={open ? faTimes : faBars} size="lg" />
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="bg-sky-blue text-gray-800 dark:bg-midnight-sky dark:text-white xl:hidden font-rocgrotesk overflow-y-auto h-[calc(100vh-var(--navbar-height))]">
            <MobileNavigationMenu path={path} onLinkClicked={close} />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
