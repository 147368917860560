import React from "react"

import { useApiEvents } from "../../../../hooks/api-events"
import { EVENTS_PAGE_PATH } from "../../../../hooks/content-sections"
import { useFocusedEvent } from "../../../../hooks/focused-event"
import { SideMenuLink } from "../side-menu-link"

export const EventsMenu: React.FC = () => {
  const events = useApiEvents()
  const { focusedEvent } = useFocusedEvent()

  if (events.length === 0) {
    return null
  }

  const eventLinks = events.map((event) => (
    <SideMenuLink
      key={event.name}
      title={event.name}
      url={`${EVENTS_PAGE_PATH}#${event.name}`}
      active={focusedEvent === event.name}
      depth={1}
    />
  ))

  return (
    <SideMenuLink title="Events" url={EVENTS_PAGE_PATH} expanded>
      {eventLinks}
    </SideMenuLink>
  )
}
