import React from "react"

import { API_SECTION_PATH, ContentSection, EVENTS_PAGE_PATH, RESOURCES_PAGE_PATH } from "../../../hooks/content-sections"
import { MobileMenuLink } from "./mobile-menu-link"
import { MobileResourcesMenu } from "./mobile-resources-menu"
import { MobileEventsMenu } from "./mobile-events-menu"

type MobileContentSectionLinksProps = {
  contentSection: ContentSection
  activePath?: string
  onLinkClicked?: () => void
}

export const MobileContentSectionLinks: React.FC<MobileContentSectionLinksProps> = ({ contentSection, activePath, onLinkClicked }) => {
  const externalLinks = contentSection.links.map(({ title, url }) => <MobileMenuLink key={url} title={title} url={url} external />)

  const childSectionLinks = contentSection.childSections.map((childContentSection) => (
    <MobileContentSectionLinks
      key={childContentSection.path}
      contentSection={childContentSection}
      activePath={activePath}
      onLinkClicked={onLinkClicked}
    />
  ))

  const articleLinks = contentSection.articles.map(({ title, path }) => (
    <MobileMenuLink key={path} title={title} url={path} onClick={onLinkClicked} active={activePath === path} />
  ))

  const pageLinks = contentSection.pages
    .filter((page) => !page.hasCustomMenu)
    .map(({ title, path }) => <MobileMenuLink key={path} title={title} url={path} onClick={onLinkClicked} active={activePath === path} />)

  const isActive = activePath?.includes(contentSection.path)

  return (
    <div className="flex flex-col gap-y-3">
      <MobileMenuLink title={contentSection.title} url={contentSection.path} active={isActive} primary />

      {isActive && (
        <div className="flex flex-col gap-y-3 pl-3">
          {externalLinks}
          {childSectionLinks}
          {articleLinks}
          {pageLinks}

          {contentSection.path === API_SECTION_PATH && (
            <div className="flex flex-col gap-y-3">
              <MobileResourcesMenu onLinkClicked={onLinkClicked} active={activePath === RESOURCES_PAGE_PATH} />

              <MobileEventsMenu onLinkClicked={onLinkClicked} active={activePath === EVENTS_PAGE_PATH} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
