import React from "react"
import { ReactChildrenProps } from "../types/ReactChildrenProps"

const storageKey = "disabled_content_filters"

function loadContentFilters(): Set<string> {
  const data = localStorage.getItem(storageKey)

  if (data == null) {
    return new Set()
  }

  return new Set(JSON.parse(data))
}

function saveContentFilters(filters: Set<string>) {
  const data = JSON.stringify(Array.from(filters))
  localStorage.setItem(storageKey, data)
}

type ContentFiltersContextValue = {
  disabledContentFilters: Set<string>
  enableContentFilter: (filter: string) => void
  disableContentFilter: (filter: string) => void
  resetContentFilters: () => void
}

const ContentFiltersContext = React.createContext<ContentFiltersContextValue>({
  disabledContentFilters: new Set(),
  enableContentFilter: () => {
    // Replaced by provider
  },
  disableContentFilter: () => {
    // Replaced by provider
  },
  resetContentFilters: () => {
    // Replaced by provider
  },
})

export const ContentFiltersProvider: React.FC<ReactChildrenProps> = ({ children }) => {
  const [disabledContentFilters, setDisabledContentFilters] = React.useState<Set<string>>(new Set())

  React.useEffect(() => {
    setDisabledContentFilters(loadContentFilters())
  }, [setDisabledContentFilters])

  const enableContentFilter = React.useCallback(
    (filter: string) => {
      setDisabledContentFilters((prevState) => {
        const updatedContentFilters = new Set(prevState)
        updatedContentFilters.delete(filter)

        saveContentFilters(updatedContentFilters)
        return updatedContentFilters
      })
    },
    [setDisabledContentFilters]
  )

  const disableContentFilter = React.useCallback(
    (filter: string) => {
      setDisabledContentFilters((prevState) => {
        const updatedContentFilters = new Set(prevState)
        updatedContentFilters.add(filter)

        saveContentFilters(updatedContentFilters)
        return updatedContentFilters
      })
    },
    [setDisabledContentFilters]
  )

  const resetContentFilters = React.useCallback(() => {
    const updatedContentFilters = new Set<string>()

    saveContentFilters(updatedContentFilters)
    setDisabledContentFilters(updatedContentFilters)
  }, [setDisabledContentFilters])

  const value: ContentFiltersContextValue = {
    disabledContentFilters,
    enableContentFilter,
    disableContentFilter,
    resetContentFilters,
  }

  return <ContentFiltersContext.Provider value={value}>{children}</ContentFiltersContext.Provider>
}

export const useContentFilters = () => {
  const context = React.useContext(ContentFiltersContext)

  if (context === undefined) {
    throw new Error("useContentFilters must be used within a ContentFiltersProvider")
  }

  return context
}
