import React from "react"
import { GatsbyBrowser } from "gatsby"

import "./src/styles/global.css"
import { ThemeProvider } from "./src/context/theme"
import { FocusedApiResourceProvider } from "./src/context/focused-api-resource"
import PageLayout from "./src/components/page/page-layout"
import { MobileNavMenuStateProvider } from "./src/context/mobile-nav-menu"
import { PreferredLanguageProvider } from "./src/context/preferred-language"
import { ContentFiltersProvider } from "./src/context/content-filters"
import { SearchStateProvider } from "./src/context/search"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => (
  <ContentFiltersProvider>
    <PreferredLanguageProvider>
      <MobileNavMenuStateProvider>
        <FocusedApiResourceProvider>
          <SearchStateProvider>
            <ThemeProvider>{element}</ThemeProvider>
          </SearchStateProvider>
        </FocusedApiResourceProvider>
      </MobileNavMenuStateProvider>
    </PreferredLanguageProvider>
  </ContentFiltersProvider>
)

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ props, element }) => {
  return (
    <PageLayout path={props.location.pathname} params={props.params}>
      {element}
    </PageLayout>
  )
}
