import React from "react"
import { Link } from "gatsby"

export type MobileMenuLinkProps = {
  title: string
  url: string
  external?: boolean
  primary?: boolean
  active?: boolean
  anchor?: string
  onClick?: () => void
}

export const MobileMenuLink = React.forwardRef<never, MobileMenuLinkProps>(function MobileMenuLink(
  { title, url, external, primary, active, anchor, onClick },
  ref
) {
  const classes = `font-rocgrotesk hover:underline py-1 ${primary ? `font-medium` : ``} ${active ? `text-tangerine` : ""}`
  const id = external ? undefined : `nav-${anchor ?? url}`

  return external ? (
    <a href={url} className={classes} id={id} ref={ref}>
      {title}
    </a>
  ) : (
    <Link to={url} className={classes} id={id} ref={ref} onClick={onClick}>
      {title}
    </Link>
  )
})
